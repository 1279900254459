import { Viewer, ViewerOptions } from 'photo-sphere-viewer';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';

import { CztWidgets } from '~/utils/views/widgets';
import { VueComponent } from '~/utils/vue-component';

import style from './Panorama.scss';
import { Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import { PanoramaInterface } from './types';

const rootClass = 'czt-panorama';

@Component({
  style,
})
export default class Panorama extends VueComponent<PanoramaInterface>
  implements PanoramaInterface {
  @Prop()
  public anchorId?: string;

  @Prop()
  public caption?: string;

  @Prop({ required: true })
  public className!: CztWidgets;

  @Prop({ type: String, required: true })
  public guid!: string;

  @Prop({ required: true })
  public image!: string;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public rotate!: boolean;

  @Prop({ required: true, type: String })
  public title!: string;

  @Ref('psvContainer')
  protected psvContainer!: HTMLDivElement;

  protected viewer: Viewer | null = null;

  protected resizeThrottle?: number;

  protected get configuration(): Partial<ViewerOptions> {
    return {
      caption: this.caption,
      panorama: this.image,
      autorotateDelay: this.rotate ? 1000 : undefined,
      loadingTxt: this.$t('app.panorama.loadingText').toString(),
      lang: {
        autorotate: this.$t('app.panorama.autorotate').toString(),
        zoom: this.$t('app.panorama.zoom').toString(),
        zoomOut: this.$t('app.panorama.zoomOut').toString(),
        zoomIn: this.$t('app.panorama.zoomIn').toString(),
        move: this.$t('app.panorama.move').toString(),
        download: this.$t('app.panorama.download').toString(),
        fullscreen: this.$t('app.panorama.fullscreen').toString(),
        menu: this.$t('app.panorama.menu').toString(),
        twoFingers: this.$t('app.panorama.twoFingers').toString(),
        ctrlZoom: this.$t('app.panorama.ctrlZoom').toString(),
        loadError: this.$t('app.panorama.loadError').toString(),
      },
    };
  }

  public mounted() {
    this.viewer = new Viewer({
      container: this.psvContainer,
      navbar: ['autorotate', 'zoom', 'caption', 'fullscreen'],
      mousewheel: false,
      touchmoveTwoFingers: true,
      ...this.configuration,
    });
    window.addEventListener('resize', this.resizeHandler);
  }

  public beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
    this.viewer?.destroy();
  }

  public render() {
    const containerClasses = ['czt-spacer'];

    if (this.isTopSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-top');
    }
    if (this.isBottomSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-bottom');
    }
    return (
      <v-sheet id={this.anchorId} class={rootClass}>
        <v-container class={containerClasses.join(' ')}>
          <div class='py-3 px-0'>
            {this.title.trim() && (
              <v-row>
                <v-col class='py-0'>
                  <Headline underscore align={Align.LEFT} level={2}>
                    {this.title}
                  </Headline>
                </v-col>
              </v-row>
            )}
            <v-responsive aspect-ratio={16 / 9}>
              <div class={`${rootClass}__container`} ref='psvContainer' />
            </v-responsive>
          </div>
        </v-container>
      </v-sheet>
    );
  }

  @Watch('configuration', { deep: true })
  protected updateView() {
    if (this.viewer) {
      this.viewer.setOptions(this.configuration);
    }
  }

  protected resizeHandler() {
    if (this.resizeThrottle) {
      window.clearTimeout(this.resizeThrottle);
    }

    this.resizeThrottle = window.setTimeout(() => {
      this.viewer?.autoSize();
    }, 50);
  }
}
